import { MessageEventData } from '@/shared/Interfaces'
import { LokiConnectionService } from '@/services/loki-connection.service'

export class ConnectionService {
  static handlers: any = {}
  static sessions: any = {}

  static message(messageData: MessageEventData) {
    LokiConnectionService.ws.send(JSON.stringify(messageData))
  }

  static dataSessionProvider(key: string, handler: any) {
    this.handlers[key] = handler
    this.message({ command: 'registerSessionProvider', provider: key })
  }
}