import { MessageEventData } from '@/shared/Interfaces'
import { ConnectionService } from '@/services/connection.service'

export class SessionService {
  static session_id: number
  static onMessage: any
  
  static init (message: MessageEventData) {
    this.session_id = message.session
  
    const provider: string = message.provider
    let bestMatch = null
    let bestMatchName: string = null
    for (const [name, entry] of Object.entries(ConnectionService.handlers)) {
      if (provider.startsWith(name)) {
        if (bestMatch === null || name.length > bestMatchName.length) {
          bestMatch = entry
          bestMatchName = name
        }
      }
    }
    if (!bestMatch) {
      throw 'No match for ' + provider
    }
    this.onMessage = bestMatch
  }
  
  static setData(key: string, value: any): void {
    ConnectionService.message({ command: 'setData', session: SessionService.session_id, key, value })
  }
  
  static dataEvent(key: string, value: any): void {
    ConnectionService.message({ command: 'dataEvent', session: SessionService.session_id, key, value })
  }
}