/* eslint-disable no-console */
import { StaticClassInstantiatedError } from '@/shared/Interfaces'

export interface ILoggingFormat {
    fontSizePercent?: number
}

export class LoggerService{
  protected static formatString?: string
  protected static _prefix: string = '[VIZIOGRAM-WEBAPP] '
  protected static _isLoggingEnabled: boolean = true

  constructor() {
    throw new StaticClassInstantiatedError('UILoggingService Not Instantiated')
  }

  static log(message: string, ...object: any) {
    if (this._isLoggingEnabled === false) return

    if (object.length) {
      console.log(`${this._prefix}${message}`, object)
    } else {
      console.log(`${this._prefix}${message}`)
    }
  }

  static warn(message: string, ...object: any) {
    if (this._isLoggingEnabled === false) return

    if (object.length) {
      console.warn(`${this._prefix}${message}: ${JSON.stringify(object)}`)
    } else {
      console.warn(`${this._prefix}${message}`)
    }
  }

  static error(message: string, ...object: any) {
    if (this._isLoggingEnabled === false) return

    if (object.length) {
      console.error(`${this._prefix}${message}: ${JSON.stringify(object)}`)
    } else {
      console.error(`${this._prefix}${message}`)
    }
  }

  static duration(message: string, startTime: Date, ...object: any) {
    if (this._isLoggingEnabled === false) return

    const operationDuration = new Date().getTime() - startTime.getTime()
    if (object) {
      console.info(`${this._prefix}${message} duration (ms): ${operationDuration}: ${JSON.stringify(object)}`)
    } else {
      console.info(`${this._prefix}${message} duration (ms): ${operationDuration}`)
    }
  }

  static formatRestObject(error: object, ...object: any) {
    // Add error object contents to the last position of the ...object rest array
    if (error) {
      (error instanceof Error) ? object.push(error.message) : object.push(String(error))
    }
    return object
  }
}

export function log(message: string, ...object: any) {
  return LoggerService.log(message, ...object)
}

/**
 * Logs an error the Conjure console.
 * @param {Error} error - the error object caught by the error handler
 * @param {string} component - The identifying location (ie Index: _bootstrap) logging the error
 * @param {string} message - The message to be displayed in the Console
 * @param {...any} object - Spread operator with N number of optional parameters
 */
export function logError(error: Error, component: string, message: string, ...object: any) {
  return LoggerService.error(`${component}: ${message}`, message, LoggerService.formatRestObject(error, ...object))
}

/**
 * Logs an error message with no error object data to Conjure console.
 * @param {string} component - The identifying location (ie Index: _bootstrap) logging the error
 * @param {string} message - The message to be displayed in the Console
 * @param {...any} object - Spread operator with N number of optional parameters
 */
export function logErrorMessage(component: string, message: string, ...object: any) {
  LoggerService.error(`${component}: ${message}`, ...object)
}

/**
 * Logs a warning to the Conjure console.
 * @param {string} component - The identifying location (ie Index: _bootstrap) logging the warning
 * @param {string} message - The warning message to be displayed in the Console
 * @param {...any} object - Spread operator with N number of optional parameters
 */
export function logWarn(component: string, message: string, ...object: any) {
  return LoggerService.warn(message, ...object)
}

export function duration(message: string, startTime: Date, ...object: any) {
  return LoggerService.duration(message, startTime, ...object)
}