import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'
import { MessageEventData, VideoELement, PlaybackErrorTypes, PlaybackEventTypes } from '@/shared/Interfaces'
import { ConnectionService } from '@/services/connection.service'
import { SessionService } from '@/services/session.service'
import { log, logErrorMessage } from '@/services/logger.service'

export class PlayerService {
  static player: VideoJsPlayer
  static videoElement: VideoELement
  static videoRootElement: HTMLElement

  static init() {
    ConnectionService.dataSessionProvider('/viziogram/player', (messageData: MessageEventData) => {
      if (messageData.event === 'newSession') {
        log('player.service.ts', 'init()', messageData.event)
        this.createPlayer()
      } else if (messageData.event == 'endSession') {
        log('player.service.ts', 'init()', messageData.event)
        this.destroyPlayer()
      } else if (messageData.event === 'valueWrite') {
        log('player.service.ts', 'init()', messageData.event)
        if (messageData.key === 'url') {
          log('player.service.ts', 'init()', messageData.key)
          SessionService.setData('progress', 0)
          if (messageData.value === '') {
            log('player.service.ts','init() : Empty Url recieved. Disposing Player')
            this.destroyPlayer()
          } else {
            if (!this.player) {
              this.createPlayer()
            }
            this.loadVideos(SessionService)
            this.loadUrl(messageData.value)
            SessionService.setData('playing', false)
          }
        } else if (messageData.key === 'playing') {
          log('player.service.ts', 'init()', messageData.key)
          if (messageData.value) {
            this.play()
          } else {
            this.pause()
          }
        }
      } else if (messageData.event === 'dataEvent') {
        if (messageData.key === 'skipBackwardForward') {
          log('player.service.ts', 'init()', messageData.key)
          this.skipBackwardForward(messageData.value)
        }
      }
    })
  }

  static createPlayer() {
    this.videoRootElement = document.getElementById('video')
    this.videoElement = document.createElement('video')
    log('player.service.ts', 'createPlayer() : Video Element created')
    this.videoElement.className = 'video-js'
    const videoOptions: VideoJsPlayerOptions = {
      autoplay: 'muted',
      controls: false,
      children: [],
      preload: 'auto',
    }
    this.videoRootElement.appendChild(this.videoElement)
    this.player = videojs(this.videoElement, videoOptions, () => Object)
    window.player = this.player
    window.video = this.videoElement
  }

  static destroyPlayer() {
    if (this.player) {
      this.player.dispose()
      log('player.service.ts', 'destroyPlayer() : Player disposed')
    }
    this.player = null
  }

  static loadVideos(session: any) {
    this.player.on(PlaybackEventTypes.LOADSTART, () => { log('player.service.ts', 'loadVideos(session): event Loadstart'); session.setData('loading', true) })
    this.player.on(PlaybackEventTypes.LOADDEDATA, () => { log('player.service.ts', 'loadVideos(session): event loadeddata'); session.setData('loading', false); this.play() })
    this.player.on(PlaybackEventTypes.DURATIONCHANGE, () => { log('player.service.ts', 'loadVideos(session): event durationchange'); session.setData('duration', this.player.duration()) })
    this.player.on(PlaybackEventTypes.TIMEUPDATE, () => { session.setData('progress', this.player.currentTime()) })
    this.player.on(PlaybackEventTypes.LOADEDMETADATA, () => { log('player.service.ts', 'loadVideos(session): event loadedmetadata'); session.setData('duration', this.player.duration()) })
    this.player.on(PlaybackEventTypes.ENDED, () => { log('player.service.ts', 'loadVideos(session): event ended'); session.dataEvent('ended', true) })
    this.player.on(PlaybackEventTypes.PLAY, () => { log('player.service.ts', 'loadVideos(session): event playing'); session.setData('playing', true) })
    this.player.on(PlaybackEventTypes.PAUSE, () => { log('player.service.ts', 'loadVideos(session): event pause'); session.setData('playing', false) })
    this.player.on(PlaybackEventTypes.ERROR, () => { log('player.service.ts', 'loadVideos(session): event error'); session.setData('error-type', this.playbackErrors()) })
  }

  /**
   * Loads the Url and starts playing the video.
   * based on the security requirements a auth token might be required for each url.
   * This token should be sent by loki and will be attached 
   * to each url for video js to authenticate and download the video.
   * @param url string
   */
  static loadUrl(url: any) {
    // videojs.Hls.xhr.beforeRequest = function(options){
    //   options.headers = {
    //     ...options.headers,
    //     'X-Token-Session': xToken
    //   }
    //   return options
    // }

    this.player.src({ type: 'application/x-mpegURL', src: url })
    log('player.service.ts', 'loadUrl(url) : Url Loaded')
  }

  /**
   * This API can be used to start playback on a player that has a source.
   */
  static play() {
    const playPromise = this.player.play()
    log('player.service.ts', 'Play()')
    if(playPromise) {
      playPromise.then(async () => {
        await this.player.play()
      }).catch(async () => {
        console.log('Unable to play automatically')
        log('player.service.ts', 'play() : Unable to play automatically')
        document.dispatchEvent(new KeyboardEvent('keydown', { keyCode: 13, key: 'Enter', code: 'Enter' }))
      })
    }
  }

  /**
   * This API can be used to pause playback on a player that is playing.
   */
  static pause() {
    if (!this.player.paused()) {
      this.player.pause()
      log('player.service.ts', 'Pause()')
    }
  }

  /**
   * This API is used to seek/skip forward and backward
   * @param skipSeconds {number} current time
   */
  static skipBackwardForward(skipSeconds: any) {
    log('player.service.ts', 'skipBackwardForward()')
    let time = this.player.currentTime() + skipSeconds
    if (time < 0) {
      time = 0
    }
    this.player.currentTime(time)
    log('player.service.ts', 'skipBackwardForward to ', skipSeconds, ' seconds. Current Time: ', this.player.currentTime(time))
  }

  /**
   * Play back error types
   * @returns {string} This is the playback error types
   */
  static playbackErrors() {
    const errorType: number = this.player.error().code
    switch (errorType) {
      case 0:
        logErrorMessage('playBackErrors()', 'playbackErrorType', PlaybackErrorTypes.MEDIA_ERR_CUSTOM, 'Failed to play the media')
        return PlaybackErrorTypes.MEDIA_ERR_CUSTOM
        break
      case 1:
        logErrorMessage('playBackErrors()', 'playbackErrorType', PlaybackErrorTypes.MEDIA_ERR_ABORTED, 'Failed to play the media')
        return PlaybackErrorTypes.MEDIA_ERR_ABORTED
        break
      case 2:
        logErrorMessage('playBackErrors()', 'playbackErrorType', PlaybackErrorTypes.MEDIA_ERR_NETWORK, 'Failed to play the media')
        return PlaybackErrorTypes.MEDIA_ERR_NETWORK
        break
      case 3:
        logErrorMessage('playBackErrors()', 'playbackErrorType', PlaybackErrorTypes.MEDIA_ERR_DECODE, 'Failed to play the media')
        return PlaybackErrorTypes.MEDIA_ERR_DECODE
        break
      case 4:
        logErrorMessage('playBackErrors()', 'playbackErrorType', PlaybackErrorTypes.MEDIA_ERR_SRC_NOT_SUPPORTED, 'Failed to play the media')
        return PlaybackErrorTypes.MEDIA_ERR_SRC_NOT_SUPPORTED
        break
      case 5:
        logErrorMessage('playBackErrors()', 'playbackErrorType', PlaybackErrorTypes.MEDIA_ERR_ENCRYPTED, 'Failed to play the media')
        return PlaybackErrorTypes.MEDIA_ERR_ENCRYPTED
        break
      default:
        logErrorMessage('playBackErrors()', 'playbackErrorType', PlaybackErrorTypes.MEDIA_ERR_UNKNOWN, 'Failed to play the media')
        return PlaybackErrorTypes.MEDIA_ERR_UNKNOWN
    }
  }
}