import { MessageEventData } from '@/shared/Interfaces'
import Config from '@/shared/Config'
import { ConnectionService } from '@/services/connection.service'
import { PlayerService } from '@/services/player.service'
import { SessionService } from '@/services/session.service'
import { log, logErrorMessage, logWarn } from '@/services/logger.service'

export class LokiConnectionService {
  static ws: WebSocket

  static connect(sessionToken?: string): void {
    console.log(`WSConnection trying to connect with token ${sessionToken}`)
    this.ws = new WebSocket(Config.webSocketUrl, Config.protocol)
    if (this.ws.readyState === 0) {
      console.log(
        `WSConnection connection established with token ${sessionToken}`
      )
      log('loki-connection.service.ts', 'connect(sessionToken) : WSConnection connection established with token', sessionToken)
    }
    
    this.bindSocketEvents(sessionToken)
  }

  static bindSocketEvents(sessionToken?: string) {
    this.ws.onerror = (event: Event) => {
      console.error('WSConnection _bindSocketEvents Websocket error', event)
      logErrorMessage('WSConnectionerror','bindSocketEvents(sessionToken) : _bindSocketEvents Websocket error' )
    }

    this.ws.onclose = () => {
      logWarn('loki-connection.service.ts', 'WS Connection has been disconnected')
      for (const session of Object.values(ConnectionService.sessions)) {
        SessionService.onMessage(session, { event: 'endSession' })
      }
      this.ws = null
      PlayerService.destroyPlayer()
      setTimeout(() => this.connect(sessionToken), 5000)
    }

    this.ws.onopen = () => {
      const urlParams = Object.fromEntries(new URLSearchParams(window.location.search).entries())
      console.log('Opening WS Connection with readyState', this.ws.readyState, sessionToken)
      log('loki-connection.service.ts', 'Opening WS Connection with readyState', this.ws.readyState, sessionToken )
      /**
       * for Testing Values
       */
      ConnectionService.message({ command: 'setData', key: '/viziogram/userId', value: 'TEST_USER_ID' })

      ConnectionService.message({ command: 'bundle', url: 'bundles/viziogram', parameters: urlParams })
      PlayerService.init()
    }

    this.ws.onmessage = (event: MessageEvent) => {
      const message: MessageEventData = JSON.parse(event.data)
      
      if (message.event === 'newSession') {
        SessionService.init(message)
      }
      if (message.session) {
        SessionService.onMessage(message)
      }
    }
  }
}
