const ENV = window.location.hostname === 'localhost' ? 'local' : 'loki'

const config: any = {
  local: {
    webSocketUrl: 'ws://127.0.0.1:4242',
    protocol: 'loki',
  },
  loki: {
    webSocketUrl: 'ws://127.0.0.1:4242',
    protocol: 'loki',
  }
}

class Config {
  static get webSocketUrl(): string {
    return config[ENV].webSocketUrl
  }

  static get protocol(): string {
    return config[ENV].protocol
  }
}

export default Config
