export interface MessageEventData {
    event?: string,
    key?: string,
    value?: number | string,
    session?: number,
    provider?: string,
    command?: string,
    url?: string,
    parameters?: {[key : string]: string}
}

export interface VideoELement extends HTMLElement {
    duration?: number
}

/**
 * Enum for Errors generated during playback
 * @enum {string}
 */
export enum PlaybackErrorTypes {
    MEDIA_ERR_UNKNOWN = 'MEDIA_ERR_UNKNOWN',
    MEDIA_ERR_CUSTOM = 'MEDIA_ERR_CUSTOM',
    MEDIA_ERR_ABORTED = 'MEDIA_ERR_ABORTED',
    MEDIA_ERR_NETWORK = 'MEDIA_ERR_NETWORK',
    MEDIA_ERR_DECODE = 'MEDIA_ERR_DECODE',
    MEDIA_ERR_SRC_NOT_SUPPORTED = 'MEDIA_ERR_SRC_NOT_SUPPORTED',
    MEDIA_ERR_ENCRYPTED = 'MEDIA_ERR_ENCRYPTED',
}

/**
 * Enum for Events during playback
 * @enum {string}
 * @param {string} LOADSTART - Fired when the user agent begins looking for media data
 * @param {string} LOADDEDATA - Fired when the player has downloaded data at the current playback position and ready to play
 * @param {string} DURATIONCHANGE - Fired when there is change in duration
 * @param {string} TIMEUPDATE - Fired when the current playback position has changed * During playback this is fired every 15-250 milliseconds.
 * @param {string} LOADEDMETADATA - Fired when the player has initial duration and dimension information
 * @param {string} ENDED - Fired when video playback ends
 * @param {string} PLAY - Indicates that playback has started or resumed
 * @param {string} PAUSE - Fired whenever the media has been paused
 * @param {string} ERROR - Fired when an error occurs
 */
export enum PlaybackEventTypes {
    LOADSTART = 'loadstart',
    LOADDEDATA = 'loadeddata',
    DURATIONCHANGE = 'durationchange',
    TIMEUPDATE = 'timeupdate',
    LOADEDMETADATA = 'loadedmetadata',
    ENDED = 'ended',
    PLAY = 'play',
    PAUSE = 'pause',
    ERROR = 'error'
}

/**
 * Interfaces for Logging Service
 */
export class CustomError extends Error {
  payload: any
  
  constructor(name: string, message: string, ...payload: any) {
    super()
    super.name = name
    super.message = message
    this.payload = payload
  }
}
  
export class StaticClassInstantiatedError extends CustomError {
  constructor(message: string) {
    super('Static Class Instantiated', message)
  }
}
  
export class NotImplementedError extends CustomError {
  constructor(message: string) {
    super('Not Implemented Error', message)
  }
}